import locales from "./locales.mjs";

export const defaultLocale = locales[0];

export const LocalePrefix = {
  "as-needed": "as-needed",
  always: "always",
  never: "never",
};

const i18nextConfig = {
  locales,
  localePrefix: LocalePrefix["always"],
  defaultLocale: locales[0],
  namespaces: [
    "landing",
    "common",
    // "footer",
    // "legal/cgv",
    // "legal/terms",
    // "legal/privacy",
  ],
};

export default i18nextConfig;
